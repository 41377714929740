<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <rect
      fill="#1A2C47"
      x="7"
      y="6.12"
      width="2"
      height="8"
      rx="1"
      ry="1"
      transform="translate(6.5 22.94) rotate(-135)"
    />
    <rect
      fill="#1A2C47"
      x="7"
      y="1.88"
      width="2"
      height="8"
      rx="1"
      ry="1"
      transform="translate(-1.81 7.38) rotate(-45)"
    />
  </svg>
</template>
