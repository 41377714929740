<template>
  <Drawer :open="showHelp" padding="lg" @close="toggleHelp(false)">
    <template #header>
      <Row class="help-header">
        <button class="btn-icon" @click="toggleHelp(false)">
          <IconCaretLeft height="28" width="28" />
        </button>

        <IconQuestion height="28" width="28" />
        <h2 style="color: inherit">{{ $t('Help') }}</h2>
      </Row>
      <hr />
    </template>

    <Stack gap="sm">
      <span class="h4">{{ $t('Need help?') }}</span>
      <small>{{ $t('Quickly find answers to your product, compliance, and HR questions.') }}</small>
      <PageLink to="/help" class="button btn-block" style="background-color: var(--peo-color-accent)">
        {{ $t('Browse Resources') }}
      </PageLink>
    </Stack>
  </Drawer>
</template>

<script setup>
import { Drawer, Row, Stack } from '@justworkshr/alma'
import PageLink from '@/components/PageLink.vue'
import IconCaretLeft from '~icons/ph/caret-left'
import IconQuestion from '~icons/ph/question'
import useHelpDrawer from '../../use/useHelpDrawer.js'

const { showHelp, toggleHelp } = useHelpDrawer()
</script>

<style scoped>
.help-header {
  color: var(--peo-color-help-header-text);
  padding: var(--space-lg);
  padding-bottom: var(--space-md);
}
</style>
