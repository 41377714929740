<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g id="Heart">
      <polygon
        class="cls-1"
        stroke="currentColor"
        points="13.28 6.72 18.5 9 13.28 11.28 11 16.5 8.72 11.28 3.5 9 8.72 6.72 11 1.5 13.28 6.72"
      />
      <polygon fill="currentColor" points="5.1 14.9 7 16 5.1 17.1 4 19 2.9 17.1 1 16 2.9 14.9 4 13 5.1 14.9" />
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
</style>
