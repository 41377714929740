<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Person">
      <path
        d="M13.93,10.05A5,5,0,0,0,15,7,5,5,0,0,0,5,7a5,5,0,0,0,1.07,3.05A8,8,0,0,0,2,17a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1A8,8,0,0,0,13.93,10.05ZM10,4a3,3,0,0,1,1.24,5.73l-.27.1-.19.06-.31.07-.16,0a3,3,0,0,1-.6,0l-.16,0-.31-.07L9,9.83l-.27-.1A3,3,0,0,1,10,4ZM4.08,16a6,6,0,0,1,3.65-4.55l.19.09.24.11a4.93,4.93,0,0,0,.72.22l.3.05A5.07,5.07,0,0,0,9.8,12l.2,0,.2,0a5.07,5.07,0,0,0,.61-.06l.3-.05a4.93,4.93,0,0,0,.72-.22l.24-.11.19-.09A6,6,0,0,1,15.92,16Z"
        fill="#848890"
      />
    </g>
  </svg>
</template>
