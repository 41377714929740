<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="129.999" height="24.074" viewBox="0 0 129.999 24.074">
    <g transform="translate(-671.628 -487.006)">
      <g transform="translate(671.628 487.006)">
        <path d="M241.564,3013H113v5.423H241.564Z" transform="translate(-112.3 -2994.349)" fill="#52b0ff" />
        <path
          d="M0,13.191l4.531-.86c.087,2.321.817,3.442,2.352,3.442,1.331,0,1.95-.619,1.95-2.408V0H13.55V12.244c0,4.791-1.981,6.766-6.766,6.766C1.826,19.01.378,16.113,0,13.191Z"
          fill="currentColor"
        />
        <path
          d="M2659.012,833.988V825h4.6v9.075c0,1.306.619,1.82,1.622,1.82,1.331,0,2.136-.817,2.136-2.711V825h4.6v13.55h-4.389v-3.658h-.118c-.675,3.169-2.167,3.987-4.2,3.987-2.854-.012-4.259-1.517-4.259-4.89Z"
          transform="translate(-2642.54 -819.893)"
          fill="currentColor"
        />
        <path
          d="M5134.006,782l2.482-1.677a3.909,3.909,0,0,0,3.8,2.1c1.275,0,1.832-.371,1.832-1.089,0-.817-.544-1.25-2.067-1.523l-1.894-.359c-2.55-.458-3.931-1.95-3.931-4.042,0-2.8,2.253-4.414,5.689-4.414,3.41,0,5.02,1.405,5.88,2.984l-2.408,1.547a3.1,3.1,0,0,0-3.009-1.863c-1.188,0-1.82.427-1.82,1.133,0,.675.514,1.059,1.82,1.306l1.919.359c2.841.514,4.229,1.678,4.229,4.2,0,2.377-1.523,4.531-5.993,4.531-3.3,0-5.534-1.263-6.537-3.2Z"
          transform="translate(-5102.22 -766.227)"
          fill="currentColor"
        />
        <path
          d="M7306.832,264.445v-7.181H7305v-2.711h1.832V251h4.6v3.553h2.222v2.711h-2.222v6.766c0,.891.359,1.244,1.034,1.244h1.188V268.1h-3.008c-2.9,0-3.813-.916-3.813-3.658Z"
          transform="translate(-7259.781 -249.446)"
          fill="currentColor"
        />
        <path
          d="M8938,825h4.989l1.863,8.53h.111l2.383-8.53h3.8l2.291,8.53h.117l2.062-8.53h3.628l-4.2,13.544h-3.962l-2.408-8.518h-.11l-2.3,8.518h-3.956Z"
          transform="translate(-8882.671 -819.893)"
          fill="currentColor"
        />
        <path
          d="M12440,779.094c0-4.327,2.823-7.094,7.038-7.094s7.039,2.767,7.039,7.094-2.823,7.094-7.039,7.094S12440,783.433,12440,779.094Zm9.261,0c0-3.5-.762-4.414-2.223-4.414s-2.222.916-2.222,4.414.761,4.414,2.222,4.414S12449.262,782.591,12449.262,779.094Z"
          transform="translate(-12362.995 -767.221)"
          fill="currentColor"
        />
        <path
          d="M15043,811.087h4.327v4.5h.118c.643-3.714,1.894-4.587,3.9-4.587h.241v4.055h-2.222c-1.331,0-1.765.427-1.765,2.037v7.527h-4.6Z"
          transform="translate(-14949.882 -805.98)"
          fill="currentColor"
        />
        <path
          d="M16725.881,18.2l-1.324,1.461V23.62H16720V5h4.557V15.895l4.705-5.819h4.1l-4.469,4.872,4.76,8.672h-4.879Z"
          transform="translate(-16616.5 -4.969)"
          fill="currentColor"
        />
        <path
          d="M18973,782l2.492-1.677a3.918,3.918,0,0,0,3.8,2.1c1.275,0,1.838-.371,1.838-1.089,0-.817-.543-1.25-2.062-1.523l-1.893-.359c-2.549-.458-3.932-1.95-3.932-4.042,0-2.8,2.254-4.414,5.7-4.414,3.41,0,5.021,1.405,5.881,2.984l-2.408,1.547a3.1,3.1,0,0,0-3.008-1.863c-1.187,0-1.82.427-1.82,1.133,0,.675.516,1.059,1.82,1.306l1.92.359c2.84.514,4.227,1.678,4.227,4.2,0,2.377-1.521,4.531-5.99,4.531-3.3,0-5.547-1.263-6.559-3.2Z"
          transform="translate(-18855.553 -766.227)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
