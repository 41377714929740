<template>
  <div v-if="open" class="overlay" :class="{ 'overlay-on': open }" aria-hidden="true" @click.stop="close" />
  <div ref="sheet" class="sheet" :class="{ open: open }" :aria-hidden="!open" @click.stop="onTapNav">
    <div class="nub-container"><div class="nub"></div></div>
    <slot />
  </div>
</template>

<script setup>
import { watchEffect } from 'vue'
import { ref, computed } from 'vue'
import { usePointerSwipe } from '@vueuse/core'

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

const positionY = ref('0')

const sheet = ref(null)

const sheetHeight = computed(() => sheet.value?.offsetHeight)

const { distanceY } = usePointerSwipe(sheet, {
  onSwipe() {
    if (distanceY.value > 0) return
    positionY.value = Math.abs(distanceY.value) + 'px'
  },
  onSwipeEnd(e, direction) {
    if (direction !== 'down') return
    if (Math.abs(distanceY.value) / sheetHeight.value >= 0.3) {
      close()
    } else {
      positionY.value = '0'
    }
  },
})

const body = document.querySelector('body')

function lockBodyScroll() {
  body.style.touchAction = 'none'
}

function unlockBodyScroll() {
  body.style.removeProperty('touch-action')
}

function onTapNav(e) {
  if (e.target.tagName === 'A') {
    close()
  }
}

function close() {
  positionY.value = '0'
  emit('close')
}

watchEffect(() => {
  if (props.open) lockBodyScroll()
  else unlockBodyScroll()
})
</script>

<style scoped>
.overlay {
  background: var(--color-black);
  inset: 0;
  position: fixed;
  opacity: 0;
}

.overlay-on {
  opacity: var(--opacity-overlay);
  z-index: 1;
}

.sheet {
  position: fixed;
  min-height: calc(50vh + var(--main-nav-height));
  max-height: 90vh;
  transition-property: background-color, transform;
  border-radius: 16px 16px 0 0;
  background-color: var(--color-nav-sheet);
  bottom: var(--main-nav-height);
  left: 0;
  right: 0;
  transform: translateY(calc(100% + 77px));
  z-index: 1;
}

.nub-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
}

.nub {
  height: 4px;
  width: 45px;
  border-radius: var(--border-radius);
  background-color: var(--color-border);
}

.sheet.open {
  transform: translateY(v-bind('positionY'));
}
</style>
