<template>
  <footer>
    <component :is="mobile ? Stack : Row" :style="!mobile && 'align-items: baseline'">
      <Stack gap="md">
        <PageLink to="/dashboard">
          <LogoJustworks height="16" width="90" aria-label="Justworks — Dashboard" />
        </PageLink>

        <small style="color: inherit">
          Copyright &copy; 2013-{{ year }} Justworks, Inc. and its affiliates. All rights reserved.
        </small>
      </Stack>

      <FlexSpace />

      <component :is="mobile ? Stack : Row" gap="md" class="links">
        <a href="tel:8885341711" aria-label="8 8 8. 5 3 4. 1 7 1 1.">(888) 534-1711</a>
        <a href="https://www.justworks.com/terms" target="_blank" rel="noreferrer">Terms of Use</a>
        <a
          href="https://justworks.com/referrals?utm_source=secure&utm_medium=footer&utm_campaign=referralprogram-secure"
          target="_blank"
          rel="noreferrer"
        >
          Referral Program
        </a>
        <a href="https://www.justworks.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
      </component>
    </component>
  </footer>
</template>

<script setup>
import { FlexSpace, Row, Stack } from '@justworkshr/alma'
import LogoJustworks from '@/components/LogoJustworks.vue'
import PageLink from '@/components/PageLink.vue'
import useBreakpoint from '@/use/useBreakpoint.js'

const year = new Date().getFullYear()

const { mobile } = useBreakpoint()
</script>

<style scoped>
footer {
  padding: 40px 0;
  border-top: 1px solid var(--peo-color-footer-border);
  color: var(--peo-color-footer-text);
}

.links a {
  color: inherit;
  font-size: 14px;
  text-wrap: nowrap;

  &:hover {
    color: var(--peo-color-footer-link-hover);
    border-color: var(--peo-color-footer-link-border-hover);
  }
}
</style>
