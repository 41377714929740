<template>
  <NavList :header-label="$t('You')" class="nav-list" :aria-label="$t('You')">
    <template #icon>
      <IconUser />
    </template>
    <template #navItems>
      <a v-if="sageMemberId" :href="benefitsSSOLink" target="_self">
        {{ $t('Benefits') }}
      </a>
      <a v-if="showTimeTrackingURL" :href="timeTrackingURL" target="_self">
        {{ $t('Time Tracking') }}
      </a>
      <PageLink v-if="memberTypesForFeature.pto.has(memberType) && !isTerminated" to="/account/time-off">
        {{ $t('Time off') }}
      </PageLink>
      <PageLink v-if="memberType !== 'third_party_admin'" to="/account/paystubs">{{ $t('Paystubs') }}</PageLink>
      <PageLink to="/account/documents">{{ $t('Documents') }}</PageLink>
      <PageLink to="/account/settings">{{ $t('Account settings') }}</PageLink>
      <PageLink v-if="mobile" to="/logout">{{ $t('Log out →') }}</PageLink>
    </template>
  </NavList>
</template>

<script setup>
import { computed } from 'vue'
import { isTerminated, viewer } from '@/viewer.js'
import NavList from '@/components/NavList.vue'
import PageLink from '@/components/PageLink.vue'
import { getTimeTrackingURL } from '@/data/integrations.js'
import { memberTypesForFeature } from '@/data/memberTypes.js'
import IconUser from '~icons/ph/user'

defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
})

const member = computed(() => viewer.value?.activeMembership.member)

const sageIntegrationHost = import.meta.env.VITE_APP_SAGE_INTEGRATION_BASE_URL

const sageMemberId = computed(() => member.value?.sage_member_id || '')
const benefitsSSOLink = computed(() =>
  sageIntegrationHost && member.value?.benefits_id
    ? `${sageIntegrationHost}?target_member_id=${member.value?.benefits_id}`
    : ''
)
const memberType = computed(() => member.value?.member_type || '')

const hasTimeTrackingIntegration = computed(
  () => viewer.value?.activeCompany.timetracking_integration_status === 'active'
)
const showTimeTrackingURL = computed(
  () =>
    hasTimeTrackingIntegration.value &&
    member.value?.flsa_exempt_status !== 'exempt' &&
    ['hourly', 'annual'].includes(member.value?.pay_basis)
)
const timeTrackingURL = computed(() => (showTimeTrackingURL.value ? getTimeTrackingURL() : ''))
</script>

<style scoped>
.nav-list {
  --nav-active-background-color: var(--color-highlight-blue);
  --nav-active-border-color: var(--color-nav-blue);
  --nav-icon-color: var(--color-nav-blue);
}
</style>
