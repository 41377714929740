<template>
  <HeaderButton :is-open="showWhatsNew" disable-down-triangle @open="toggleWhatsNew(true)">
    <template #icon>
      <WhatsNewSparkle v-if="!postNotSeenLength" height="28" width="26" />
      <div v-else class="notification-circle">
        <strong>{{ postNotSeenLength }}</strong>
      </div>
    </template>
    <template #text>{{ $t('What’s new') }}</template>
  </HeaderButton>
</template>

<script setup>
import useWhatsNewDrawer from '@/layouts/internals/use/useWhatsNewDrawer.js'
import WhatsNewSparkle from '../assets/header/WhatsNewSparkle.vue'
import HeaderButton from './HeaderButton.vue'

const { showWhatsNew, toggleWhatsNew, postNotSeenLength } = useWhatsNewDrawer()
</script>

<style scoped>
.notification-circle {
  background-color: var(--peo-color-alert-count);
  border-radius: var(--radius-round);
  color: var(--color-white);
  font-size: var(--text-sm);
  height: 23px;
  width: 23px;
  line-height: 18.2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
