import { computed, reactive } from 'vue'
import { viewer, getViewer } from '@/viewer.js'
import useApiFetch from '@/use/useApiFetch.js'
import postTaskToCustomerIO from '@/utils/customerio.js'

const companyTasks = computed(() => viewer.value?.company_onboarding_states?.[viewer.value?.activeCompany?.company_id])
const memberTasks = computed(
  () => viewer.value?.member_onboarding_states?.[viewer.value?.activeMembership?.member?.member_id]
)
/**
 * @typedef {'bank_account'
 *   | 'general_company_info'
 *   | 'self_onboarded'
 *   | 'invite_team'
 *   | 'state_tax_info'
 *   | 'explore_benefits'} enrollmentTask
 */

/** @type {enrollmentTask[]} */
const enrollment = [
  'bank_account',
  'general_company_info',
  'self_onboarded',
  'invite_team',
  'state_tax_info',
  'explore_benefits',
  'manage_workers_comp',
]

/** @typedef {'csa' | 'fee_agreement'} agreementTask */

/** @type {agreementTask[]} */
const agreement = ['csa', 'fee_agreement']

/**
 * @typedef {'access_dashboard'
 *   | 'bank_account_reconnect'
 *   | 'bank_account_verify'
 *   | 'annual_pay_schedule'
 *   | 'hourly_pay_schedule'
 *   | 'salary_non_exempt_pay_schedule'
 *   | 'key_company_contacts'
 *   | 'pto_policies'
 *   | 'payroll_history_info'
 *   | 'payroll_approval'
 *   | 'manage_workers_comp'} onboardingTask
 */

/** @type {onboardingTask[]} */
const onboarding = [
  'access_dashboard',
  'bank_account_reconnect',
  'bank_account_verify',
  'annual_pay_schedule',
  'hourly_pay_schedule',
  'salary_non_exempt_pay_schedule',
  'key_company_contacts',
  'pto_policies',
  'payroll_history_info',
  'payroll_approval',
  // 'manage_workers_comp', this task is excluded here, despite being included in the jsdoc type
]

const stages = reactive({ enrollment, agreement, onboarding })

const started = reactive({
  enrollment: false,
  agreement: false,
  onboarding: false,
})

/** @typedef {enrollmentTask | agreementTask | onboardingTask} task */

/** @param {task} task */
export function isCompanyTaskPending(task) {
  return companyTasks.value && companyTasks.value[task] === false
}

/** @param {task} task */
export function isMemberTaskPending(task) {
  return memberTasks.value && memberTasks.value[task] === false
}

/**
 * @param {task} task
 * @param {boolean} refresh
 */
export async function completeCompanyTask(task, refresh = true) {
  if (isCompanyTaskPending(task)) {
    const { patch } = useApiFetch('/data/onboarding-state')
    await patch({
      subject_id: viewer.value.activeCompany.company_id,
      subject_type: 'company',
      onboarding_item: task,
      status: true,
    })
    if (refresh) await getViewer()

    // no need to await this "fire and forget" request
    postTaskToCustomerIO(task)
  }
}

/** @param {task} deferredTask */
export function deferCompanyTask(deferredTask) {
  stages.enrollment = stages.enrollment.filter((task) => task !== deferredTask)
  stages.onboarding.unshift(deferredTask)
}

/**
 * @param {task} task
 * @param {boolean} refresh
 */
export async function completeMemberTask(task, refresh = true) {
  if (isMemberTaskPending(task)) {
    const { patch } = useApiFetch('/data/onboarding-state')
    await patch({
      subject_id: viewer.value.activeMembership.member.member_id,
      subject_type: 'member',
      onboarding_item: task,
      status: true,
    })
    if (refresh) await getViewer()
  }
}

function setStarted(stage) {
  started[stage] = true
}

export const isEnrolling = computed(() => isCompanyTaskPending('csa') || isCompanyTaskPending('fee_agreement'))

export default function useCustomerStages() {
  const currentStage = computed(() => {
    const tasks = viewer.value?.company_onboarding_states?.[viewer.value?.activeCompany?.company_id]
    if (!tasks) return { stage: 'done' }

    for (const stage of Object.keys(stages)) {
      if (stages[stage].every((task) => tasks[task] === false)) {
        return { stage: stage, partiallyComplete: false, started: started[stage] }
      }
      if (stages[stage].some((task) => tasks[task] === false)) {
        return { stage: stage, partiallyComplete: true, started: started[stage] }
      }
    }

    return { stage: 'done' }
  })

  const showEnrollment = computed(() => {
    if (isEnrolling.value) return true
    return (
      (currentStage.value.stage === 'enrollment' && isCompanyTaskPending('access_dashboard')) ||
      currentStage.value.stage === 'agreement' ||
      (currentStage.value.stage === 'onboarding' &&
        currentStage.value.partiallyComplete === false &&
        currentStage.value.started === false)
    )
  })

  return { stages, currentStage, tasks: companyTasks, setStarted, showEnrollment }
}
