import { computed } from 'vue'
import { t } from '@/i18n.js'

export default {
  employee: {
    title: 'Employee',
  },
  contractor: {
    title: 'Contractor',
  },
  company: {
    title: 'Business',
  },
  accountant: {
    title: 'Accountant',
  },
  partner: {
    title: 'Business partner',
  },
}

export const memberTypesForFeature = {
  tax_withholding: new Set(['full_time_employee', 'paid_owner']),
  date_of_birth: new Set(['full_time_employee', 'third_party_admin', 'unpaid_owner', 'paid_owner', 'contractor']),
  bank_account: new Set(['full_time_employee', 'paid_owner', 'unpaid_owner', 'contractor']),
  flsa_exempts: new Set(['full_time_employee', 'paid_owner']),
  pay_basis: new Set(['full_time_employee', 'paid_owner', 'contractor']),
  ssn: new Set(['full_time_employee', 'paid_owner', 'unpaid_owner', 'contractor']),
  requires_office: new Set(['full_time_employee', 'paid_owner']),
  receive_payments: new Set([
    'full_time_employee',
    'contractor',
    'paid_owner',
    'unpaid_owner',
    'international_contractor',
  ]),
  has_compensation: new Set(['full_time_employee', 'paid_owner']),
  owners_draws: new Set(['paid_owner', 'unpaid_owner']),
  pto: new Set(['full_time_employee', 'paid_owner', 'unpaid_owner']),
  sex: new Set(['full_time_employee', 'paid_owner', 'unpaid_owner']),
}

export const localizedMemberTypes = computed(() => ({
  full_time_employee: t('Employee'),
  contractor: t('Contractor (1099)'),
  third_party_admin: t('Third party admin'),
  paid_owner: t('Paid owner'),
  unpaid_owner: t('Unpaid owner'),
  international_contractor: t('International contractor'),
  part_time_employee: t('Part-time employee'),
  temp: t('Temp'),
  vendor: t('Vendor'),
  international_employee: t('International employee'),
  employee_eor: t('Employee EOR'),
  paid_intern: t('Paid intern'),
}))
