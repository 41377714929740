<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M9.65 6.93001C10.0454 6.5379 10.3378 6.05416 10.5012 5.52181C10.6646 4.98946 10.6939 4.42498 10.5866 3.87856C10.4793 3.33214 10.2386 2.8207 9.88598 2.38971C9.53336 1.95871 9.07971 1.62152 8.56536 1.4081C8.05102 1.19469 7.49191 1.11166 6.93775 1.16639C6.38358 1.22113 5.85152 1.41194 5.38887 1.72186C4.92621 2.03178 4.5473 2.45121 4.28581 2.94286C4.02432 3.43451 3.88835 3.98315 3.89 4.54001V27.3H9.59"
      fill="#DF5A31"
    />
    <path
      d="M9.65 6.93001C10.0454 6.5379 10.3378 6.05416 10.5012 5.52181C10.6646 4.98946 10.6939 4.42498 10.5866 3.87856C10.4793 3.33214 10.2386 2.8207 9.88598 2.38971C9.53336 1.95871 9.07971 1.62152 8.56536 1.4081C8.05102 1.19469 7.49191 1.11166 6.93775 1.16639C6.38358 1.22113 5.85152 1.41194 5.38887 1.72186C4.92621 2.03178 4.5473 2.45121 4.28581 2.94286C4.02432 3.43451 3.88835 3.98315 3.89 4.54001V27.3H9.59"
      stroke="#15246D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.77 6.93001C34.2393 6.4568 34.5582 5.85541 34.6866 5.20146C34.8151 4.54751 34.7474 3.87018 34.4919 3.25462C34.2365 2.63906 33.8048 2.11274 33.2511 1.74183C32.6974 1.37093 32.0464 1.17198 31.38 1.17001H7.26C7.92645 1.17198 8.57744 1.37093 9.13113 1.74183C9.68483 2.11274 10.1165 2.63906 10.3719 3.25462C10.6274 3.87018 10.6951 4.54751 10.5666 5.20146C10.4382 5.85541 10.1193 6.4568 9.65 6.93001H33.77Z"
      fill="#DF5A31"
      stroke="#15246D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.75 6.92999H9.59V39H33.75V6.92999Z"
      fill="white"
      stroke="#15246D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13.57 12.42H21.38" stroke="#15246D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M30.17 10.41L26.68 14.43L25.14 12.7"
      stroke="#15246D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13.57 19.34H21.38" stroke="#15246D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M30.17 17.33L26.68 21.35L25.14 19.61"
      stroke="#15246D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13.57 26.25H21.38" stroke="#15246D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M30.17 24.24L26.68 28.26L25.14 26.53"
      stroke="#15246D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M13.57 33.17H21.38" stroke="#15246D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M30.17 31.16L26.68 35.18L25.14 33.44"
      stroke="#15246D"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
