<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Office">
      <g>
        <path
          fill="#848890"
          d="M16,6H13V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V8A2,2,0,0,0,16,6ZM8,16H7V14H8Zm1,0V14a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1v2H4V4h7V16Zm7,0H13V8h3Z"
        />
        <rect fill="#848890" x="5" y="5" width="2" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="8" y="5" width="2" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="5" y="7" width="2" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="8" y="7" width="2" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="5" y="9" width="2" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="8" y="9" width="2" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="14" y="9" width="1" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="14" y="11" width="1" height="1" rx="0.25" ry="0.25" />
        <rect fill="#848890" x="14" y="13" width="1" height="1" rx="0.25" ry="0.25" />
      </g>
    </g>
  </svg>
</template>
