<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="thick_dollar" data-name="thick dollar">
      <path
        d="M11.62,16.91v.89a.2.2,0,0,1-.2.2H9.82a.2.2,0,0,1-.2-.2V17a9.43,9.43,0,0,1-4.41-1.65.51.51,0,0,1-.13-.69l.81-1.22a.49.49,0,0,1,.41-.22.52.52,0,0,1,.29.09A7.19,7.19,0,0,0,10.5,14.6c1.3,0,2.05-.55,2.05-1.43v-.05c0-1-1.4-1.37-3-1.85-.23-.06-.47-.13-.7-.21C7.16,10.51,5.47,9.62,5.47,7.4V7.35c0-2.33,1.79-3.81,4.15-4V2.2a.2.2,0,0,1,.2-.2h1.6a.2.2,0,0,1,.2.2V3.46a9.44,9.44,0,0,1,3,1.11.5.5,0,0,1,.17.67l-.72,1.29a.5.5,0,0,1-.43.25.54.54,0,0,1-.25-.06,7.22,7.22,0,0,0-3.29-1c-1.18,0-1.85.55-1.85,1.33v0c0,1,1.42,1.35,3,1.85,1.93.6,4.1,1.47,4.1,3.85v0C15.32,15.2,13.77,16.55,11.62,16.91Z"
        fill="#848890"
      />
    </g>
  </svg>
</template>
