<template>
  <HeaderButton :is-open="showHelpMenu" @open="showHelpMenu = true">
    <template #icon><HelpChatBubble height="28" width="26" /></template>
    <template #text>{{ $t('Help') }}</template>

    <template #menu-content>
      <div class="popover-wrapper">
        <Popover :show="showHelpMenu" direction="bot" anchor="right" @close="showHelpMenu = false">
          <Stack gap="sm">
            <span class="h4">{{ $t('Need help?') }}</span>
            <small>{{ $t('Quickly find answers to your product, compliance, and HR questions.') }}</small>
            <PageLink to="/help" class="button btn-block" style="background-color: var(--peo-color-accent)">
              {{ $t('Browse Resources') }}
            </PageLink>
          </Stack>
        </Popover>
      </div>
    </template>
  </HeaderButton>
</template>

<script setup>
import { ref } from 'vue'
import { Popover, Stack } from '@justworkshr/alma'
import PageLink from '@/components/PageLink.vue'
import HelpChatBubble from '../assets/header/HelpChatBubble.vue'
import HeaderButton from './HeaderButton.vue'

const showHelpMenu = ref(false)
</script>

<style scoped>
.popover-wrapper :deep(.popover) {
  width: 258px;
  padding: var(--space-md);
  z-index: 2;
  top: 40px;
}
</style>
