import { createRouter, createWebHistory } from 'vue-router'
import { featureIsOn } from '@/features.js'
import {
  getViewer,
  viewer,
  loaded,
  setActiveCompany,
  viewerCan,
  isActiveMemberTypeRestricted,
  isActiveCompanyPEO,
} from '@/viewer.js'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { resolvePEORedirectPath, buildPEOFlipToPath } from '@/data/peoRedirects.js'
import useCustomerStages from '@/use/useCustomerStages.js'
import useGlobalError from '@/use/useGlobalError.js'
import { updateAvailable } from './check-version.js'

const { resetGlobalError } = useGlobalError()
const { showEnrollment } = useCustomerStages()

const routes = [...setupLayouts(generatedRoutes), ...[{ path: '/', redirect: '/dashboard' }]]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export async function beforeEach(to, from, params) {
  const { viewer, showEnrollment, loaded, isActiveCompanyPEO, isActiveMemberTypeRestricted } = params
  const { viewerCan, featureIsOn, setActiveCompany, getViewer } = params

  if (!loaded.value) {
    await getViewer()
  }

  const companyID = to.params.company_id || to.query.co
  if (companyID) setActiveCompany(companyID)

  // route auth
  const routeIsNoAuth = to.meta.noAuth
  const routeNeedsAuth = !to.meta.noAuth

  // if viewer is undefined, treat as logged out user
  const userIsLoggedInWithOkta = !!viewer.value?.okta_id
  const userHasJWPayroll = !!viewer.value?.user_id
  const userHasCompanies = Object.keys(viewer.value?.companies || {}).length > 0

  // redirect to login unless logged in to Okta or Ghost member
  if (routeNeedsAuth && !userHasJWPayroll && !userIsLoggedInWithOkta) {
    const params = { s: to.fullPath }
    const query = new URLSearchParams(params)
    const apiHost = import.meta.env.VITE_APP_API_HOST
    resetGlobalError()
    window.location.replace(`${apiHost}/sso/login?${query}`)
    return false
  }

  // route profile
  const routeIsDashboard = to.path === '/dashboard'
  const routeIsEnroll = to.path == '/enroll'
  const routeRequiresPermission = !!to.meta.permissions
  const routeHasMemberTypeRestrictions = !!to.meta.restrictedMemberTypes
  const feature = to.meta.feature

  // permissions and restrictions
  const viewerHasPermission = routeRequiresPermission && viewerCan(...to.meta.permissions)
  const memberTypeIsRestricted =
    routeHasMemberTypeRestrictions && isActiveMemberTypeRestricted(...to.meta.restrictedMemberTypes)

  // disallow
  if (!isActiveCompanyPEO.value && feature && !featureIsOn(feature)) return false

  if (isActiveCompanyPEO.value && (!feature || !featureIsOn(feature))) {
    const peoRedirectPath = resolvePEORedirectPath(to)
    const flipToRoute = buildPEOFlipToPath(peoRedirectPath, companyID ?? viewer.value?.activeCompany?.company_id)
    window.location.assign(import.meta.env.VITE_APP_CLOCKWORK_HOST + flipToRoute)
    return false
  }

  if (routeRequiresPermission && !viewerHasPermission) return false
  if (routeHasMemberTypeRestrictions && memberTypeIsRestricted) return false

  // allow
  if (routeIsNoAuth) return

  // redirect
  if (userIsLoggedInWithOkta && !userHasCompanies) return '/welcome'
  if (routeIsDashboard && showEnrollment.value) return '/enroll'
  if (routeIsEnroll && !showEnrollment.value) return '/dashboard'
}

router.beforeEach((to, from) =>
  beforeEach(to, from, {
    featureIsOn,
    getViewer,
    isActiveCompanyPEO,
    isActiveMemberTypeRestricted,
    loaded,
    setActiveCompany,
    showEnrollment,
    viewer,
    viewerCan,
  })
)

router.afterEach(() => {
  if (updateAvailable()) window.location.reload()
  resetGlobalError()
})

export default router
