<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Analytics">
      <g>
        <path fill="#848890" d="M5,15H7a2,2,0,0,0,2-2V11A2,2,0,0,0,7,9H5a2,2,0,0,0-2,2v2A2,2,0,0,0,5,15Zm0-4H7v2H5Z" />
        <path
          fill="#848890"
          d="M12,15h3a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2v9A2,2,0,0,0,12,15ZM12,4h3v9H12Z"
        />
        <path fill="#848890" d="M17,16H3a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Z" />
      </g>
    </g>
  </svg>
</template>
