<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Manage_copy" data-name="Manage copy">
      <path
        d="M16.23,8a3.85,3.85,0,1,0-7.08-2.1c0,.12,0,.23,0,.35A3.81,3.81,0,0,0,8,6a3.85,3.85,0,0,0-3.22,6A6,6,0,0,0,2,17a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1,6,6,0,0,0-.82-3H18a1,1,0,0,0,1-1A6,6,0,0,0,16.23,8ZM13,4a1.86,1.86,0,1,1-1.86,1.86A1.86,1.86,0,0,1,13,4ZM8,8a1.85,1.85,0,0,1,1,3.41H9a1.82,1.82,0,0,1-.46.21l-.06,0a1.87,1.87,0,0,1-1,0l-.06,0A1.82,1.82,0,0,1,7,11.41H7A1.85,1.85,0,0,1,8,8ZM4.13,16a4,4,0,0,1,2.24-2.65l.05,0a3.82,3.82,0,0,0,.7.24l.05,0a3.67,3.67,0,0,0,1.66,0l.05,0a3.82,3.82,0,0,0,.7-.24l.05,0A4,4,0,0,1,11.87,16Zm7.16-4-.06,0a3.83,3.83,0,0,0,.63-2.1c0-.12,0-.23,0-.35a3.64,3.64,0,0,0,2.79-.16A4,4,0,0,1,16.87,12Z"
        fill="#848890"
      />
    </g>
  </svg>
</template>
