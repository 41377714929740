<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Clipboard">
      <path
        d="M15,4H13.24l-.45-.89A2,2,0,0,0,11,2H9A2,2,0,0,0,7.21,3.11L6.76,4H5A2,2,0,0,0,3,6V16a2,2,0,0,0,2,2H15a2,2,0,0,0,2-2V6A2,2,0,0,0,15,4ZM9,4h2l1,2H8Zm6,12H5V6H6A2,2,0,0,0,8,8h4a2,2,0,0,0,2-2h1Z"
        fill="#848890"
      />
    </g>
  </svg>
</template>
