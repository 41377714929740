import IconAnalytics from '../components/peo/assets/sidebar/Analytics.vue'
import IconBuilding from '../components/peo/assets/sidebar/Building.vue'
import IconClipboard from '../components/peo/assets/sidebar/Clipboard.vue'
import IconDollar from '../components/peo/assets/sidebar/Dollar.vue'
import IconEOYTaskList from '../components/peo/assets/sidebar/EOYTaskList.vue'
import IconPerson from '../components/peo/assets/sidebar/Person.vue'
import IconPersonManage from '../components/peo/assets/sidebar/PersonManage.vue'

export default {
  analytics: IconAnalytics,
  company: IconBuilding,
  eoy: IconEOYTaskList,
  hr: IconClipboard,
  manage: IconPersonManage,
  payments: IconDollar,
  you: IconPerson,
}
