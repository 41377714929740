<template>
  <button class="btn-icon">
    <Row gap="xs" class="avatar-row">
      <figure
        class="avatar text-xs"
        :class="{ focused }"
        :style="{
          backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
        }"
      >
        {{ initials }}
      </figure>
      <span class="down-triangle" :class="{ focused }">&#9660;</span>
    </Row>
  </button>
</template>

<script setup>
import { computed } from 'vue'
import { Row } from '@justworkshr/alma'

const props = defineProps({
  firstName: { type: String, default: '' },
  lastName: { type: String, default: '' },
  imageUrl: { type: String, default: '' },
  focused: { type: Boolean, required: true },
})

const initials = computed(
  () => props.firstName.trim().charAt(0).toUpperCase() + props.lastName.trim().charAt(0).toUpperCase()
)
</script>

<style scoped>
.avatar-row .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  top: 0;
  background-color: var(--peo-color-avatar-background);
  color: #a9adb5;
  overflow: visible;
  border: 1px solid var(--peo-color-border);
  border-radius: 100%;
  font-weight: 700;
  height: 36px;
  width: 36px;
}

.avatar-row .avatar.focused {
  border-color: var(--peo-color-accent);
}

.avatar-row .down-triangle {
  height: 16px;
  width: 16px;
  color: var(--peo-color-line);
  transform: scale(1, 0.5);
}

.avatar-row .down-triangle.focused {
  color: var(--peo-color-accent);
}
</style>
