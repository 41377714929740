import { computed } from 'vue'
import { viewer } from '@/viewer.js'

export const DEV = import.meta.env.DEV || import.meta.env.BASE_URL.includes('dev.justworkslabs.com/')

const loggedOutfeatures = computed(() => ({}))

const features = computed(() => {
  return viewer.value?.activeMembership?.feature_flags || {}
})

/** @param {string} feature */
export function featureIsOn(feature) {
  return features.value[feature] || false
}

/** @param {string} feature */
export function loggedOutfeatureIsOn(feature) {
  return loggedOutfeatures.value[feature] || false
}
