<template>
  <div class="popover-wrapper">
    <Popover :show="showUserPopover" :persist="persist" direction="bot" anchor="right" @close="showUserPopover = false">
      <div class="popover-box">
        <div class="menu-section">
          <div v-for="item in menuHeaderItems" :key="item.label">
            <PageLink :to="item.to" class="page-link">
              <div class="menu-item">{{ item.label }}</div>
            </PageLink>
          </div>
        </div>

        <hr />

        <div class="menu-section">
          <div v-for="[company_id, { dba, entity_name, origin }] in companyEntries" :key="company_id" class="menu-item">
            <a :href="buildComanyLink(company_id)" class="page-link">
              <Stack gap="xs" class="company-link" :class="{ selected: selectedCompanyId === company_id }">
                <strong>{{ dba || entity_name }}</strong>
                <span class="text-xs" style="font-weight: 300">{{ origin === 'peo' ? 'PEO' : 'Payroll' }}</span>
              </Stack>
            </a>
          </div>
        </div>

        <hr />

        <Stack class="menu-section" gap="sm">
          <template v-if="mobile">
            <button class="page-button btn-frameless" @click="toggleWhatsNew(true)">
              <Row class="menu-item" gap="sm">
                <IconSparkle height="20" width="20" />
                {{ $t('What’s new') }}
              </Row>
            </button>

            <button class="page-button btn-frameless" @click="toggleHelp(true)">
              <Row class="menu-item" gap="sm">
                <IconQuestion height="20" width="20" />
                {{ $t('Help') }}
              </Row>
            </button>
          </template>

          <Row class="menu-item action">
            {{ $t('Language') }}
            <FlexSpace />
            <div>
              <LanguageSelector @focus="persist = true" @blur="persist = false" />
            </div>
          </Row>

          <PageLink class="page-link" to="/logout">
            <Row class="menu-item" gap="sm">
              <IconLogout height="20" width="20" />
              {{ $t('Log out') }}
            </Row>
          </PageLink>
        </Stack>
      </div>
    </Popover>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { FlexSpace, Popover, Row, Stack } from '@justworkshr/alma'
import { t } from '@/i18n.js'
import useHelpDrawer from '@/layouts/internals/use/useHelpDrawer.js'
import useWhatsNewDrawer from '@/layouts/internals/use/useWhatsNewDrawer.js'
import { viewer } from '@/viewer.js'
import LanguageSelector from '@/components/LanguageSelector.vue'
import PageLink from '@/components/PageLink.vue'
import { buildPEOFlipToPath } from '@/data/peoRedirects.js'
import useBreakpoint from '@/use/useBreakpoint.js'
import IconQuestion from '~icons/ph/question'
import IconLogout from '~icons/ph/sign-out'
import IconSparkle from '~icons/ph/sparkle'

const showUserPopover = defineModel({ type: Boolean })

const persist = ref(false)

const { mobile } = useBreakpoint()
const { toggleWhatsNew } = useWhatsNewDrawer()
const { toggleHelp } = useHelpDrawer()

const menuHeaderItems = computed(() => [{ label: t('Account settings'), to: '/account/settings' }])

const companyEntries = computed(
  () => Object.entries(viewer.value.companies).sort((a, b) => a[1].entity_name.localeCompare(b[1].entity_name)) || []
)
const selectedCompanyId = computed(() => viewer.value.activeCompany.company_id)

function buildComanyLink(company_id) {
  if (viewer.value.companies[company_id]?.origin === 'peo') {
    return import.meta.env.VITE_APP_CLOCKWORK_HOST + buildPEOFlipToPath('/dashboard', company_id)
  }

  return `/dashboard?co=${company_id}`
}
</script>

<style scoped>
.popover-wrapper :deep(.popover) {
  z-index: 2;
  top: 40px;
}

.popover-wrapper .popover-box {
  width: 371px;
}

.popover-wrapper .popover-box hr {
  margin: 0;
  color: var(--peo-color-line);
}

.popover-wrapper .popover-box .menu-section {
  margin: 12px;
  max-height: 550px;
  overflow-y: auto;
}

.popover-wrapper .popover-box .menu-item {
  padding: 8px 12px;
  border-radius: var(--radius-xs);
  color: var(--peo-color-menu-item);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  &:hover {
    transition: 0.25s;
    background-color: var(--peo-color-menu-item-hover);
  }
}

.popover-wrapper .popover-box .menu-item.action {
  color: var(--peo-color-menu-company-item);

  &:hover {
    background-color: inherit;
  }
}

.popover-wrapper .popover-box :deep(.page-button),
.popover-wrapper .popover-box :deep(.page-link) {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  padding-bottom: 3px;
}

.popover-wrapper .popover-box :deep(.page-button) {
  align-self: flex-start;
  justify-content: flex-start;
  padding: 0;
}

.popover-wrapper .popover-box .company-link {
  color: var(--peo-color-menu-company-item);
}

.popover-wrapper .popover-box .company-link.selected {
  color: var(--peo-color-menu-item);
}
</style>
